@import 'src/styles/variables';
@import 'src/styles/mixins';

.footer {
  @include text(12px, 400, 12px);
  background-color: $light-gray;
  display: flex;
  justify-content: center;
}

.logo {
  height: fit-content;
  user-select: none;

  @include vp-1024 {
    display: none;
  }
}

.wrapper {
  display: flex;
  gap: 74px;

  padding: 36px;
  max-width: 1368px;
  width: 100%;

  @include vp-1024 {
    padding: 24px 20px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contacts {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 74.5px;
  margin-bottom: 22px;

  @include vp-1024 {
    flex-direction: column;
    row-gap: 36px;
    margin-bottom: 36px;
  }

  &Info {
    display: flex;
    gap: 36px;

    @include vp-1024 {
      flex-direction: column;
      gap: 24px;
    }
  }
}

.phone {
  display: flex;
  flex-direction: column;
}

.address {
  display: flex;
  flex-direction: column;
  min-width: max-content;

  @include vp-1024 {
    flex-wrap: wrap;
    min-width: auto;
  }

  &Text {
    @include text(14px, 400, 14px);
    font-style: normal;
  }

  &Title {
    @include text(12px, 400, 20px);
    color: $gray;
  }
}

.links {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  padding: 16px 0;
  border-bottom: 1px solid rgba($color: $gray, $alpha: 0.3);
  margin-bottom: 16px;

  @include vp-1024 {
    border-bottom: none;
    gap: 0;
    padding: 0;
    margin-bottom: 36px;
  }
}

.footerLinksItem {
  @include vp-1024 {
    width: 100%;
    border-bottom: 1px solid rgba($color: $gray, $alpha: 0.3);
  }
}

.workPeriod {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba($color: $gray, $alpha: 0.3);
}

.disclaimer {
  display: flex;
  flex-direction: column;

  &Title {
    @include text(12px, 400, 20px);

    margin-bottom: 8px;

    color: $gray;
  }

  &Text {
    @include text(10px, 400, 20px);
    color: $gray;
  }
}
